body {
  font-feature-settings: "kern", "liga", "pnum";
  color: #4a4a4a;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.35;
  /*font-family: Lato, "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif !important;*/
  /*-webkit-font-smoothing: subpixel-antialiased;*/
  /*font-size: 13px;*/
  /*line-height: 20px;*/
}

.admin-sync-options-root {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.admin-sync-options-grid .col-1 {
  padding-right: 8px;
}

.admin-sync-options-grid {
  margin-top: 10px;
  margin-left: 20px;
}

.name {
  padding-left: 10px;
}

.moveable {
  cursor: move;
}

.clickable {
  cursor: pointer;
}

.form-horizontal .control-group {
  margin-bottom: 10px !important;
}

.alert {
  margin-bottom: 2px !important;
}

ul {
  margin: 0 0 0 0 !important;
}

.provider_button {
  width: 217px;
  border: solid 1px #2ea6c8;
}

.provider_button:hover {
  background-color: whitesmoke;
}

.provider_button a {
  color: #2ea6c8;
}

.image_div {
  padding: 10px;
  border-right: solid 1px #2ea6c8;
  display: inline-table;
}

.text_div {
  padding-left: 10px;
  display: inline-table;
}

.provider_button:hover {
  background-color: whitesmoke;
}

.provider_button a {
  color: #2ea6c8;
}

.amount {
  display: table;
  font-size: 25px;
}

.link {
  display: inline;
  padding-right: 10px;
}

.link a {
  color: white !important;
  font-size: 16px !important;
  font-weight: 100;
}

.icon-white {
  margin-top: 0 !important;
}

.link-button {
  padding: 6px;
}

.right {
  float: right;
}

.selected {
  background-color: #404549;
}

li.ui-state-default {
  display: inline-table;
  float: right;
  padding-right: 5px;
}

ul.ui-tabs-nav {
  background-color: #585858;
  border-radius: 3px;
  padding: 3px;
}

a.ui-tabs-anchor {
  color: white;
}

.ui-state-active a {
  font-weight: bold;
}

.inline {
  display: inline-table;
  white-space: normal;
}

.item {
  padding: 3px 6px;
  position: relative;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: solid 1px whitesmoke;
}

.modal-item {
  padding: 10px;
}

.login_method {
  display: inline-table;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 5px;
  padding: 10px;
  width: 55px;
  text-align: center;
}

#options {
  width: 200px;
  background-color: whitesmoke;
  margin-top: 6px;
  z-index: 1000;
  position: fixed;
  right: 2px;
  top: 43px;
  border: solid 1px #ccc;
  border-radius: 3px;
}

#device_options {
  width: 250px;
  background-color: whitesmoke;
  margin-top: 6px;
  z-index: 1000;
  position: fixed;
  right: 2px;
  top: 43px;
  border: solid 1px #ccc;
  border-radius: 3px;
}

#alert_notifications {
  width: 250px;
  background-color: whitesmoke;
  margin-top: 6px;
  z-index: 1000;
  position: fixed;
  right: 2px;
  top: 43px;
  border: solid 1px #ccc;
  border-radius: 3px;
}

.options-item {
  border-bottom: solid 1px #ccc;
  padding: 8px;
}

.options-item:hover {
  background: white;
}

.options-item a {
  color: gray;
}

.graph_table_row_selected {
  background-color: #cdcdcd;
}

.block {
  display: block;
}

.full {
  width: 100%;
}

.logo {
  max-width: 30px;
}

.sidenav {
  border-bottom: solid 1px #f5f5f5;
  width: 200px;
  height: 100%;
  background-color: #2d2e33;
  position: absolute;
}

.sidenav ul {
  padding-left: 5px;
}

.sidenav ul li {
  line-height: 20px !important;
  padding-top: 5px;
  padding-left: 6px;
  text-transform: lowercase;
}

.sidenav-group-title {
  color: gray;
  padding-left: 10px;
  font-weight: 500;
  margin-top: 59px;
}

.maincontent {
  left: 0;
  right: 0;
  bottom: 0;
  top: 64px;
}

.icon {
  margin-top: 5px !important;
}

li a:hover,
a:focus {
  color: black;
}

.time-periods-table .day-label {
  text-align: right;
  padding-right: 6px;
}

th.time-label {
  font-weight: normal;
  font-size: 11px;
}

.ui-selected {
  background-color: #f39814 !important;
}

legend {
  display: block;
  width: 100%;
  padding: 0 !important;
  font-size: 17px !important;
  color: #555 !important;
  border: 0 !important;
  margin-bottom: 0 !important;
  line-height: 15px !important;
}

label.error {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: red;
  border-color: red;
}

input.error {
  border-color: red !important;
}

.error-text {
  color: red;
}

/* .tableheader span {
  font-size: 17px !important;
  color: #555 !important;
  font-weight: 500 !important;
  display: inline-table;
  text-transform: uppercase;
} */

.right-align {
  text-align: right !important;
}

.padded {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.information-block-title {
  font-size: 12px;
  color: #666;
  font-weight: 500;
  text-transform: uppercase;
}

.information-block-title i {
  margin-top: 3px;
  margin-right: 6px;
}

.information-block-row {
  margin-bottom: 4px;
  padding: 3px 3px 3px 6px;
  background-color: whitesmoke;
  border-radius: 3px;
}

.information-block-row a {
  color: gray !important;
}

.graph-title {
  margin-bottom: 7px;
  color: #666;
  display: block;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: solid 1px #eee;
  padding: 6px;
}

.graph-title-with-form {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.checkbox-bumpdown {
  margin-top: 3px;
}

.tableheader-search {
  margin-top: -5px;
}

table {
  font-size: 12px;
}

a.tooltip-a:link {
  color: #add8e6;
}

a.tooltip-a:visited {
  color: #add8e6;
}

a.tooltip-a:hover {
  color: #e3e3e3;
}

a.tooltip-a:active {
  color: #fafad2;
}

div.blocked-image {
  float: right;
}

div.blocked-image img {
  height: 200px;
}

.link-button i {
  line-height: inherit !important;
}

label.error {
  padding-top: 0 !important;
}

.ui-state-highlight {
  border: 1px solid #fcefa1;
  height: 25px;
  /* background: #fbf9ee url(images/ui-bg_glass_55_fbf9ee_1x400.png) 50% 50% repeat-x; */
  color: #363636;
}

.timeselector-bar {
  cursor: pointer;
}

.legend .title {
  padding-left: 5px;
  margin-bottom: 2px;
  border: 5px;
  list-style: none;
}

.description {
  font-weight: normal;
}

.table th {
  background-color: #f0f0f0;
}

#website-favicon {
  width: 16px;
  height: 16px;
}

input[readonly].ro {
  border: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  background-color: #f8f8f2;
  position: relative;
  display: inline-block;
  margin: -6px 5px 0 5px;
  width: 85px;
  cursor: pointer;
}

table#tp-days > tbody > tr > td > input[type="checkbox"] {
  position: relative;
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
}

table#tp-days {
  border-radius: 3px;
  border: solid 1px rgb(204, 204, 204);
  border-collapse: separate;
  background-color: rgb(255, 255, 255);
  padding: 16px;
}

table#tp-days th {
  text-align: center;
  font-weight: 400;
  font-feature-settings: "kern", "liga", "pnum";
  font-family: "lato";
  font-size: 14px;
  line-height: 1.2;
  color: #000;
  height: 32px;
}

table#tp-days td {
  text-align: center;
  height: 32px;
  width: 50px;
}

.all-days {
  border-right: solid 1px rgb(204, 204, 204);
}

.header-branding {
  font-size: 21px;
  top: 5px;
  position: relative;
  color: white;
}

hr {
  margin: 10px 0 !important;
}

.content-box {
  background-color: white;
}

.heading-logo {
  padding-top: 8px;
  min-width: 50px;
  padding-left: 7px;
}

.sublink a:hover {
  text-decoration: none;
}

.sidenav-link-selected a {
  font-weight: bolder;
}

.extra_bottom_padding {
  padding-bottom: 10px;
}

.form-horizontal .control-label {
  text-align: left !important;
}

.top-nav-button {
  border-radius: 2px;
  padding: 7px 14px 7px 14px;
  margin-left: 5px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
}

.pop-color {
  background-color: #2ea6c8;
}

.pop-color:hover {
  background-color: #57cdef;
}

.pop-font-color {
  color: #57cdef;
}

.white {
  background-color: white;
}

.font-white {
  color: white;
}

.dark-button {
  background-color: #666;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 11px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-color: #333;
}

.add_list_item_button {
  background-color: #666;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 11px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-color: #333;
}

.save_button {
  background-color: #666;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  display: inline-table;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-color: #333;
}

.sync_button {
  background-color: #666;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  display: inline-table;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-color: #333;
}

.reset_button {
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  display: inline-table;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  background-color: #999;
  border: 0 #333;
}

.dark-button:hover {
  background-color: gray;
  color: #fff;
}

.inner-div-padding {
  padding: 26px;
}

.inner-div-padding-table {
  padding: 10px;
}

.subtitle-new {
  text-transform: uppercase;
}

.main-title {
  font-size: 15px;
}

.button-right-padding {
  margin-right: 4px;
}

.graph-option .graph_selected {
  border-bottom: solid 2px orange;
}

.graph_selected {
  border-bottom: solid 2px orange;
}

.text-area-list {
  background-color: #999;
  border-radius: 3px;
  color: white;
  margin-bottom: 5px;
  padding: 1px;
}

.text-area-list b {
  margin-left: 10px;
}

.text-area-list-editor-input {
  margin: 0;
}

.modal-header > h3 {
  margin-bottom: 20px;
}

.navigation_selected {
  background-color: white;
}

.sublink:hover {
  background-color: #626364;
}

.tooltip {
  opacity: 1 !important;
}

td {
  vertical-align: top;
}

.header-buttons {
  float: right;
}

.heading_button {
  background-color: #666;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  display: inline-table;
  font-weight: 400;
  line-height: 26px;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-color: #333;
}

.heading_button:hover {
  color: white !important;
}

.user_dashboard_heading_button:hover {
  text-decoration: underline;
}

.info_box_toggle {
  margin-left: 5px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.info_box_toggle:hover {
  background-color: #dddddd;
  cursor: pointer;
}

.info_box_toggle.active {
  background-color: #dddddd;
  border-bottom: none;
}

.info_box {
  display: inline-block;
  position: absolute;
  margin-left: -20px;
  min-height: 100px;
  width: 340px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  padding: 3px;
  -webkit-box-shadow: 0 0 4px 0 #969696;
  -moz-box-shadow: 0 0 4px 0 #969696;
  box-shadow: 0 0 4px 0 #969696;
}

.info_box_close {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.info_box_close:hover {
  background-color: #dddddd;
  cursor: pointer;
}

.info_box_heading {
  display: inline-block;
  font-weight: bold;
}

.info_box_content {
  border-top: 1px solid #ebebeb;
  margin-right: 25px;
  padding-left: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.info_box_information {
  padding: 3px;
}

.info_box_operations {
  padding: 3px;
}

.info_box_element_heading {
  border-bottom: 1px solid #4da9dd;
  width: 100%;
}

#alias_select_add {
  float: right;
  margin-top: 5px;
}

.chosen-container {
  min-width: 99px !important;
  margin-top: 0;
}

.criteria-controls > .chosen-container {
  min-width: 99px !important;
  width: auto !important;
  margin-top: -9px !important;
}

.criteria-control {
  white-space: nowrap;
}

.criteria-label {
  /*font-weight: 500;*/
  font-size: 12px;
  border-left: solid 4px #44c4e7;
  padding-left: 5px;
}

.criteria-controls {
  font-size: 13px;
  display: inline-table;
  margin-left: 20px;
}

.criteria-content {
  font-weight: 500;
}

.zero-padding-right {
  padding-right: 0 !important;
}

/* Use this next selector to style things like font-size and line-height: */

.chosen-container-single .chosen-single div {
  height: auto !important;
}

.support-modal {
  background-color: #fff;
  width: 800px;
  border: #ff8000 solid 2px;
  border-radius: 5px;
  box-shadow: 0 8px 6px -6px black;
  text-align: left;
  display: none;
  padding: 5px;
}

.support-modal-header {
  color: #ffffff;
  background: #ff8000;
  background-image: linear-gradient(#ff8000, #df7401);
  background-size: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 18px;
  padding: 15px;
  margin: -6px -6px 10px;
}

.close {
  cursor: pointer;
  background: 0 0;
  border: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}

#support_message {
  width: 90%;
  height: 150px;
}

.modal-header h3 {
  color: #333;
  line-height: 0 !important;
}

.lw-bubble {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: 160px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  background-color: #f7f7f7;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  padding-top: 40px;
}

.lw-bubble-header {
  font-size: 11px;
  position: relative;
  font-weight: 700;
  color: #999;
}

.lw-bubble:hover {
  background-color: #e7e7e7;
}

.pd-chart-container-background-y-title {
  color: #999;
  position: absolute;
  top: 10px;
  right: 6px;
}

.pd-chart-label-y {
  font-weight: 700;
  color: #999;
}

.positive {
  padding-top: 10px;
  padding-bottom: 10px;
  border-left: 8px solid #90e070;
  background-color: #f0fff0;
  padding-left: 10px;
  margin-bottom: 5px;
}

.negative {
  padding-top: 10px;
  padding-bottom: 10px;
  border-left: 8px solid #fbeed5;
  background-color: #fcf8e3;
  padding-left: 10px;
  margin-bottom: 5px;
}

.control-description {
  margin-left: 180px;
  margin-top: 10px;
  max-width: 40%;
  color: gray;
}

.warning-color {
  background-color: #ff8c00;
}

.warning-color:hover {
  background-color: #ffa343;
}

.archived-no {
  background-color: green;
  color: white;
  border-radius: 3px;
  padding: 5px;
  opacity: 0.7;
}

.archived-yes {
  background-color: gray;
  color: white;
  border-radius: 3px;
  padding: 5px;
  opacity: 0.7;
}

.form-group-divider {
  border-radius: 3px;
  padding: 10px;
  border: solid 1px #e0e0e0;
  margin-bottom: 10px;
}

.form-group-label {
  border-top: solid 1px #e7e7e7;
  padding: 20px;
  display: inline-table;
  border-left: solid 1px #e7e7e7;
  border-right: solid 1px #e7e7e7;
  background-color: whitesmoke;
}

.form-group-container > .form-group-divider {
  border-radius: 0;
}

.modal-body {
  max-height: inherit !important;
}

.modal {
  top: 30px !important;
  border-radius: 2px !important;
}

.modal select {
  width: 100% !important;
}

.modal .chosen-container {
  display: block !important;
  width: 100% !important;
}

.criteria_space > .control-group > .controls {
  margin-left: 180px !important;
  display: block !important;
}

a > i.fa,
a > svg.svg-inline--fa {
  color: black;
}

#pdf_report_content {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
  margin: 2px 0 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  float: left;
  min-width: 200px;
  z-index: 3000;
}

#pdf_report_content:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

#pdf_report_content:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: "";
}

.pdf_report_email_invalid::-webkit-input-placeholder {
  color: #ff3333;
}

.pdf_graph_container {
  width: 21cm;
}

.signature_drop_down {
  z-index: 1000;
  border-right: solid 1px #ccc;
  border-left: solid 1px rgb(148, 127, 127);
  border-bottom: solid 1px #ccc;
  max-height: 300px;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: white;
  width: 76%;
  top: 0px;
}

.signature_drop_down_element_selected {
  background-color: #3875d7;
  background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(20%, #3875d7), color-stop(90%, #2a62bc));
  background-image: -webkit-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: -moz-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: -o-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
  color: #fff !important;
}

.signature_drop_down_element__clickable:hover {
  background-color: #3875d7;
  background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(20%, #3875d7), color-stop(90%, #2a62bc));
  background-image: -webkit-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: -moz-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: -o-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
  color: #fff !important;
}

.signature_drop_down_element__clickable {
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px !important;
  color: #333;
}

.signature_drop_down_element_disabled {
  color: #848d95 !important;
  background: whitesmoke !important;
}

.category__category {
  font-size: 12px;
  opacity: 0.5;
}

#tools {
  width: 200px;
  background-color: whitesmoke;
  margin-top: 6px;
  z-index: 1000;
  position: fixed;
  right: 2px;
  top: 43px;
  border: solid 1px #ccc;
  border-radius: 3px;
}

.device_options_information {
  padding: 10px;
  background-color: white;
}

.device_options_information_node {
  padding-bottom: 15px;
}

.device_options_information_info {
  font-size: 10px;
}

.options-item-information {
  background: white;
}

#config_change_menu {
  width: 300px;
  background-color: whitesmoke;
  margin-top: 6px;
  color: grey;
  z-index: 1000;
  position: fixed;
  right: 2px;
  top: 43px;
  border: solid 1px #ccc;
  border-radius: 3px;
}

.sidenav-heading-div {
  padding-top: 15px;
  padding-left: 18px;
  font-weight: bolder;
}

.sidenav-heading-div:first-child {
  padding-bottom: 8px;
  padding-top: 14px;
}

.sublink-title {
  color: #555 !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bolder;
}

.sublink-toggle {
  padding-left: 25px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.sublink-toggle-title {
  color: #555 !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bolder;
}

.modal-body {
  max-height: calc(100vh - 210px) !important;
}

.modal-body-overflow {
  max-height: calc(100vh - 210px) !important;
  overflow-y: auto;
}

.modal-body .StickyTableHeader {
  top: -15px;
}

.permission {
  background-color: #999;
  border-radius: 3px;
  padding: 5px;
  color: white;
  margin-bottom: 5px;
}

.chosen-container {
  background-color: #f7f7f7 !important;
  -webkit-border-radius: 0.25em !important;
  -moz-border-radius: 0.25em !important;
  border-radius: 0.25em !important;
  border: 1px solid #ccc !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  display: inline-block !important;
  font-size: 14px !important;
  min-height: 44px !important;
  padding: 0 0.8em !important;
  -webkit-appearance: none !important;
  color: #333 !important;
  width: 95% !important;
}

.modal .chosen-container {
  display: block !important;
  width: 95% !important;
}

.uploaded_image_template {
  border: 1px solid #ccc !important;
  max-width: 200px;
  border-radius: 3px;
  margin-top: 10px;
}

.dialog_subheading {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.dialog_subheading_desc {
  color: gray;
}

.checkbox_label {
  display: inline-block;
  padding-top: 2px;
  vertical-align: middle;
  padding-right: 10px;
}

.no_form_div {
  padding-top: 10px;
  padding-bottom: 10px;
}

.remove_criteria_button {
  display: table-cell;
}

.selected_group {
  padding: 10px;
  border: solid 1px #ccc;
  width: 89%;
  border-radius: 3px;
  margin-bottom: 5px;
}

.auth_exception_row {
  padding: 10px;
  border: solid 1px #ccc;
  width: 94%;
  border-radius: 3px;
  margin-bottom: 5px;
  display: table;
}

.auth_exception_row__name {
  display: table-cell;
  padding-top: 8px;
  padding-bottom: 8px;
}

.auth_exception_row__remove {
  display: table-cell;
  float: right;
}

.modal__title_navigation_item {
  display: inline-block;
  padding-right: 5px;
}

.modal__title_navigation_item_selected {
  font-weight: bolder;
}

.main-body-app {
  height: 100%;
  display: table;
}

.main-body-body {
  display: table-cell;
  width: 100%;
  padding-left: 10px;
  background: #ebebeb;
  border-left: solid 1px #e0e0e0;
}

.main-body-right-panel {
  min-width: 400px;
  display: table-cell;
  border-left: solid 2px #e5e5e5;
  vertical-align: top;
}

.selected {
  font-weight: bold;
}

.sort_icon {
  padding-left: 5px;
}

.separator {
  padding-left: 3px;
  padding-right: 3px;
  color: gray;
}

.right-panel-heading {
  background-color: #f0f0f0;
  padding-top: 14px;
  padding-left: 11px;
  padding-bottom: 12px;
  color: #555 !important;
  font-size: 13px;
  text-transform: uppercase;
}

.right-panel-heading-sub {
  background-color: whitesmoke;
  padding-top: 14px;
  padding-left: 11px;
  padding-bottom: 12px;
  color: #555 !important;
  font-size: 13px;
  text-transform: uppercase;
}

.right-panel-line {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px whitesmoke;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.formgroup {
  margin-bottom: 15px;
  background: #fff;
}

.formgroup-content-left {
  display: table-cell;
  max-width: inherit !important;
}

.formgroup-content-right {
  display: table-cell;
  max-width: inherit !important;
}

.formgroup-content-fullwidth {
  max-width: 100% !important;
}

.formgroup-content-nopadding {
  margin: 0 0 0 0 !important;
}

.formgroup_search {
  margin: 0;
  padding-left: 15px;
  display: inline-block;
}

.main-body-body-table {
  display: table-cell;
  width: 100%;
  background-color: #f0f0f0;
  border-left: solid 1px #e0e0e0;
}

.interfaces-ip-address {
  padding-top: 15px;
  padding-bottom: 15px;
}

.interfaces-ip-address-remove {
  padding-left: 10px;
}

.status_value_link {
  float: right;
  padding-right: 10px;
}

.criteria-remove {
  display: inline-block;
  padding-right: 5px;
  padding-left: 6px;
}

.criteria-group > .mui-form--inline {
  display: inline-block;
}

.criteria-group {
  padding-top: 10px;
}

.criteria-group > .mui-form--inline > .mui-textfield > input {
  margin-bottom: 0 !important;
}

.criteria-group > .mui-textfield > input {
  margin-bottom: 0 !important;
}

.formgroup-listitem-topline {
  display: table;
  width: 100%;
}

.formgroup-listitem-state {
  display: table-cell;
  border-right: solid 1px #e0e0e0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 25px;
}

.formgroup-listitem-title {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  width: 100%;
  display: table-cell;
}

.formgroup-listitem-state-ok {
  background-color: green;
  color: white;
}

.formgroup-listitem-state-fail {
  background-color: red;
  color: white;
}

.formgroup-listitem {
  border-bottom: solid 1px whitesmoke;
}

[class*="span"] {
  margin-left: 0 !important;
  margin-right: 20px;
}

.or {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: gray;
  font-size: 12px;
}

.modal-pane {
  border: solid 1px #e0e0e0;
  padding: 15px;
  margin-bottom: 20px;
}

.signature_item {
  background-color: white !important;
  -webkit-border-radius: 0.25em !important;
  -moz-border-radius: 0.25em !important;
  border-radius: 0.25em !important;
  border: 1px solid #ccc !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
  padding: 0 0.8em !important;
  width: 100% !important;
  -webkit-appearance: none !important;
  color: #333 !important;
  margin-bottom: 10px !important;
  padding-top: 10px !important;
  clear: both;
  padding-bottom: 10px !important;
}

.mui-textfield-no-marginbottom {
  margin-bottom: 0 !important;
}

.burable {
  outline: none;
}

.selected-options {
  margin-top: 5px;
}

.red {
  color: red;
}

.green {
  color: gray;
}

.up {
  background-color: #90e070;
  color: white;
  border-radius: 3px;
  padding: 5px;
  opacity: 0.7;
}

.down {
  background-color: #ff6600;
  color: white;
  border-radius: 3px;
  padding: 5px;
  opacity: 0.7;
}

.standard-pill {
  background: white;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.standard-pill-text {
  font-size: 20px;
  color: #555 !important;
  font-weight: 500 !important;
  text-align: center;
}

.standard-pill-description {
  text-align: center;
}

.network-status-icon {
  max-width: 25px;
}

.rightpadded {
  padding-right: 3px;
}

.formgroup-table {
  width: 100%;
}

.formgroup-table td {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  border-bottom: solid 1px whitesmoke;
  font-size: 14px;
}

.pull-right {
  float: right;
  margin-right: 15px;
}

.pd-main-chart-container {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 97%;
  display: inline-block;
  vertical-align: top;
  margin-right: 0;
  position: relative;
  z-index: auto;
  margin-left: 20px;
  margin-top: 20px;
}

.pd-chart {
  width: 100%;
}

.pd-chart-container-background {
  background-color: #f7f7f7;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.pd-chart-container-background-y {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  width: 39px;
  background-color: #f1f1f1;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-right: 1px solid #e7e7e7;
}

.pd-chart-container {
  min-height: 100%;
  text-align: center;
}

.pd-chart-label-x {
  font-weight: 700;
  color: #999;
}

.highcharts-container {
  overflow: visible !important;
}

.graph_container {
  margin-bottom: 60px;
}

.pd-chart-tooltip {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: center;
  position: relative;
  background-color: #555;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
}

.pd-chart-tooltip p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 5px;
}

.pd-chart-tooltip h2 {
  font-size: 24px;
  color: #fff;
  margin: 0;
}

.wide-modal {
  width: 80%;
  left: 0;
  margin-left: 10%;
  display: block;
}

.information-block-title-subnote {
  float: right;
  font-size: 12px;
  color: gray;
}

.load-link {
  color: #337ab7;
  text-align: center;
}

.formgroup-element {
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
}

.formgroup-element-title {
  display: inline-block;
  width: 110px;
  vertical-align: top;
  padding-top: 10px;
}

.formgroup-element-fields {
  display: inline-block;
  width: 80%;
}

.formgroup-element-fields .mui-textfield {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  display: inline-block !important;
  width: 100%;
}

.formgroup-element-fields .mui-select {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  display: inline-block !important;
  width: 100%;
}

.mui-textfield > input,
.mui-textfield > textarea {
  border-radius: 0 !important;
}

.mui-textfield__rounded > input,
.mui-textfield__rounded > textarea {
  border: 1px solid #ccc;
  border-radius: 4px !important;
  padding: 2px 6px;
}

.modal-content {
  border-radius: 0 !important;
}

.right-link {
  float: right;
  padding-right: 10px;
}

.host_details_key {
  display: inline-block;
}

.host_details_value {
  display: inline-block;
  padding-left: 3px;
}

.formgroup_title_rightlink_button {
  float: right;
  background: white;
  margin-right: 14px;
  display: inline-table;
  margin-top: 18px;
  cursor: pointer;
}

.formgroup_title legend {
  padding-top: 24px !important;
  padding-left: 24px !important;
}

.gray-desc {
  color: gray;
  padding-left: 24px;
  padding-top: 4px;
  font-size: 11px;
}

.tracebutton {
  padding-left: 10px;
  padding-right: 10px;
  background: white;
  padding-top: 4px;
  padding-bottom: 4px;
  border: solid 1px #e7e7e6;
  border-radius: 3px;
  display: inline-table;
  margin-top: 3px;
  margin-left: 10px;
  cursor: pointer;
}

.tracebutton > .fa {
  padding-right: 7px;
}

.full_page_loader {
  height: 100px;
  text-align: center;
  padding: 1em;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  position: fixed;
  left: 50%;
}

#svg_loader {
  enable-background: new 0 0 50 50;
}

.loading_element {
  color: #e0e0e0;
}

.nodata {
  padding-left: 2px;
  padding-top: 2px;
}

.busy_indicator {
  padding-top: 13px;
}

.statusBox {
  text-align: center;
  height: 10px;
  margin-top: 4px;
}

.statusBoxRed {
  background: red;
}

.statusBoxGreen {
  background: green;
}

.statusBoxGray {
  background: gray;
}

.red {
  color: red;
}

.green {
  color: green;
}

.gray {
  color: gray;
}

.mobilezone-username-table-link {
  font-size: 14px;
  padding-left: 10px;
}

.mobilezone-pill {
  border-top: 1px solid rgba(76, 72, 72, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 120px;
  min-height: 120px;
  margin-right: 1px;
}

.mobilezone-panel-color-ok {
  background-color: #a3be8c;
  color: white;
}

.mobilezone-panel-color-warn {
  background-color: #ebcb8b;
  color: white;
}

.mobilezone-panel-color-risk {
  background-color: #bf616a;
  color: white;
}

.mobilezone-panel .col-md-3 {
  padding-left: 0;
  padding-right: 0;
}

.mobilezone-panel {
  overflow: auto;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}

.mobilezone-pill-text {
  font-size: 25px;
  color: #555 !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 17px;
  padding-top: 17px;
}

.mobilezone-pill-icon {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobilezone-pill-description {
  text-align: center;
  font-size: 15px;
}

.mobilezone-pill-white-text {
  font-size: 30px;
  color: #fff !important;
  font-weight: 500 !important;
  text-align: center;
}

.mobilezone-pill-white-description {
  text-align: center;
  color: #fff !important;
  font-size: 20px;
}

.mobilezone-pill-close {
  cursor: pointer;
  top: 15px;
  left: 10px;
  position: absolute;
  border: 0;
  z-index: 5;
}

.formgroup-element-fields-inline > .mui-textfield {
  width: auto !important;
  padding-right: 16px;
}

.formgroup-element-fields-inline > .mui-select {
  width: auto !important;
  padding-right: 10px;
}

.formgroup-element-fields-inline > .mui-checkbox {
  width: auto !important;
  display: inline !important;
  padding-right: 10px;
}

.signature_item_remove {
  padding-left: 5px;
}

.listselector-item-remove {
  padding-left: 2px;
}

.dumb_busy_indicator {
  display: inline-block;
  float: left;
  padding-right: 5px;
}

.formgroup_button_title {
  margin: 0;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 60px;
}

.no_content_padding {
  padding: 20px;
}

.letter-panel {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  background: white;
}

.letter-panel-footer {
  border-top: 1px solid #e2e2e2;
  padding-bottom: 10px;
  padding-top: 10px;
  background: #f5f5f5;
  height: 70px;
  padding-right: 12px;
  margin-bottom: 20px;
}

.letter-panel-footer-save-button:focus {
  outline: 0 !important;
}

.toolitem {
  margin-bottom: 20px;
}

.spacer {
  clear: both;
}

.navbar__tabs_tab_square {
  padding-top: 5px;
}

.fa-bordered {
  border: solid 1px white;
  padding: 10px;
}

.day_selector {
  margin-right: 10px;
}

.formgroup_search .mui-textfield {
  margin-bottom: 0 !important;
}

.selectableComponent:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.selectableComponent {
  cursor: pointer;
}

.info_pill_value {
  font-size: 15px;
}

a.hide_link {
  text-decoration: none;
}

a:hover.hide_link {
  text-decoration: none;
}

.mobilezone_app_icon {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.mobilezone_app_label {
  margin-left: 10px;
}

.pointer {
  cursor: pointer;
}

.info_pill_title {
  font-size: 10px;
}

h2 {
  margin-top: 0 !important;
}

.beta-pill {
  display: inline-block;
  padding-left: 40px;
}

tr.highlight-row {
  background-color: #ffe2e2;
  font-weight: 900;
}

.modal-backdrop {
  z-index: -1;
}

.header_right_button {
  float: right;
  margin-right: 14px;
  display: inline-table;
  margin-left: 24px;
  cursor: pointer;
}

.formgroup-modal {
  margin-bottom: 15px;
}

/* magic tricks for a responsive iframe: https://blog.theodo.com/2018/01/responsive-iframes-css-trick/ */
.video_player-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  box-sizing: border-box;
}

.video_player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.video_description {
  margin: 10px;
  max-height: 22em;
  overflow-y: scroll;
}
.video_description::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.mitm_alert {
  padding-top: 6px;
  padding-right: 10px;
}

.mitm_alert_text {
  padding-left: 4px;
}

.video-sizing-container {
  width: 500px !important;
}

.pageheader {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.pageheader-buttons {
  display: inline-block;
  float: right;
}

.pageheader-buttons * .mui-select {
  padding-top: 0px !important;
  padding-right: 10px;
  min-width: 300px;
}

#globalDateRange:hover {
  background: rgba(0, 0, 0, 0.1);
}

.nuselect_search_field {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.nuselect_search_open > .nuselect_search_field {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
}

.nuselect_search_field.invalid {
  border-color: red;
}

.nuselect_search {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.nuselect_search_field_placeholder {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nuselect_search_field_input {
  height: 34px;
  padding-right: 10px;
  vertical-align: middle;
}

.nuselect_search_field_input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 14px;
  /* For IE 8 compatibility */
  padding: 8px 8px 12px;
  /* For IE 8 compatibility */
  -webkit-appearance: none;
}

.nuselect_search_field_search_icon_zone {
  cursor: pointer;
  color: #aaa;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
}

.nuselect_search_selection {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  /*top: 100%;*/
  /*width: 100%;*/
  z-index: 3;
  -webkit-overflow-scrolling: touch;
}

.nuselect_search_select_list {
  max-height: 198px;
  overflow-y: auto;
}

.nuselect_search_select_item {
  box-sizing: border-box;
  background-color: #fff;
  color: #666666;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  text-align: left;
}

.nuselect_search_select_item:hover {
  background-color: #ebf5ff;
  color: #333;
}

.nuselect_search_select_item_truncate_info {
  box-sizing: border-box;
  background-color: #fff;
  color: #888888;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  font-style: italic;
}

.nuselect_item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 4px;
  border-radius: 3px;
  border-bottom: solid 1px whitesmoke;
}

.nuselect_selected {
  padding-top: 5px;
}

.nuselect_selected_no_items {
  font-size: 14px;
  padding-top: 5px;
  padding-left: 8px;
}

.nuselect_select_label {
  font-size: 11px;
  color: gray;
  padding-left: 5px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.nuselect_select_label.show {
  opacity: 1;
  visibility: visible;
}

.dialog_content_class {
  overflow-y: scroll !important;
  /*overflow-y: inherit !important;*/
}

.nuselect_selected_title {
  border-bottom: solid 1px whitesmoke;
  padding-left: 2px;
  padding-bottom: 5px;
  font-weight: 500;
}

.quarentined {
  background-color: red;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
}

.keywordSearch {
  margin-right: 5px;
}

.indentedSelector {
  margin-left: 30px;
} /*! IMPORTANT
    These utils must stay at the bottom of the file so they are selected
    when there is equal specificity between selectors */
/* Utils */
.flex {
  display: flex;
}

.mg-0 {
  margin: 0;
}
/* End Utils */
