@use 'sass:color';

$radius: 30px;
.ToggleGroup {
    display: inline-block;
    border: 2px solid #EEEEEE;
    padding: 3px;
    border-radius: $radius;

    .Toggle {
        margin-right: 4px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.Toggle {
    background: unset;
    border: none;
    outline: none;
    border-radius: $radius;
    padding: 5px 10px;
    font-size: 14px;

    &:hover:not(.Toggle--selected) {
        background: color.scale($color: $foundation-blue, $lightness: 90%)
    }
}

.Toggle--selected {
    color: $white;
    background: $foundation-blue;
}