.mui-form .label {
    margin-bottom: 8px;
    float: left;
}

.mui-form .right {
    float: right;
}

.mui-form .label--bold {
    font-weight: $text-bold-weight;
}