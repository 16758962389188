$login-background: #255577;
$login-title: #1899d6;

@mixin background-position {
  background-repeat: no-repeat;
  background-position-x: 10%;
  height: 400px;
  width: 385px;
  position: absolute;
  top: 18%;
  left: 9%;
}

#login-main-content {
  background-color: $login-background;
  height: 100%;

  .background {
    background-image: url("../images/background_logo_linewize.svg");
    @include background-position;
  }

  .flex-row-center {
    background-color: $login-background;
  }

  .privacy {
    font-size: $small-font-size;
    color: $white;

    a {
      text-decoration: none;
      color: $white;
      opacity: 0.75;
      &:hover {
        color: $white;
        opacity: 1;
      }
      &:focus {
        color: $white;
        opacity: 0.75;
      }
    }
    span {
      margin-left: 5px;
    }
  }
}

.login-container {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  margin: 10px 0 25px;
  padding: 10px 30px 20px;
  font-family: Lato, sans-serif;
  text-align: center;
  color: $text-color;
  width: 410px;
  display: block;
  text-align: center;
  margin-top: 8%;

  #logo {
    background-image: url("../images/icon_linewize.svg");
    height: 70px;
    width: 75px;
    display: inline-flex;
    background-repeat: no-repeat;
    margin-top: -45px;
    margin-bottom: 15px;
  }

  h1 {
    font-size: $page-title-lage-font-size;
    color: $login-title;
    margin: 0px;
  }

  h1.title-password-reset {
    color: $text-color;
  }

  p {
    font-size: $default-font-size;
    margin: 30px 0px 0px 0px;
  }

  p.subtitle {
    @include normal-font-weight;
    a {
      font-weight: bold;
    }
  }

  h3 {
    font-size: $page-title-font-size;
    margin: 5px;
  }

  .login-input-container {
    text-align: left;
    padding: 30px 10px 10px 10px;

    h4 {
      font-weight: 600;
      font-size: $default-font-size;
    }

    label {
      font-weight: bold;
    }
    div.ready {
      @include message-ready;
    }
    div.info {
      @include message-info;
    }
    div.error {
      @include message-error;
    }

    #googleSignInButton {
      height: 36px;
      cursor: pointer;
      background-color: $white;
      border: $text-input-border;
      border-radius: 4px;
      text-align: center;
      margin-top: 10px;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }

      &:hover {
        background-color: $input-background-color;
      }

      &:active {
        background-color: $white;
      }

      div.googleLogo {
        width: 5%;
        height: inherit;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../images/logo_google.svg");
        display: inline-block;
        margin-top: 9px;
      }

      div.signinText {
        color: $text-color;
        font-weight: 500;
        margin-top: -15px;
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;
        margin-top: -49px;
        font-size: $default-font-size;
      }
    }

    div.error {
      color: $error-text-color;
    }

    .login-separator {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 15px;

      .login-separator-line {
        border-top: $thick-border;
        flex-grow: 1;
      }

      .login-separator-text {
        margin-top: -4px;
        margin-left: 4px;
        margin-right: 4px;
        line-height: 1;
        font-size: $default-font-size;
      }
    }

    input,
    button {
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .button-password-reset {
      margin-top: 10px;
    }

    .link-container {
      text-align: center;
      a {
        color: $button-color;
        text-decoration: none;
        text-align: center;
        margin-top: 10px;
        display: inline-flex;
      }

      a.link-as-button {
        @include link-as-button;
        &:hover {
          color: $white;
        }
        &:active {
          color: $white;
        }
      }
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(8px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

div.error > input {
  border-bottom: $error-input-border;
}

div.error > input:focus {
  border-bottom: $error-input-border-thick;
}

.loginSeparator:before,
.loginSeparator:after {
  background: $colors-neutrals-60;
  height: 1px;
  flex: 1;
  content: "";
}

.chakra-input.login-error {
  background: $colors-input-background-error;
  outline: 1px solid $colors-input-outline-error;
  outline-offset: 0px;
  border: none;
}

.chakra-input.login-error:focus {
  background: $colors-input-background-error;
}

.fa-chevron-left {
  color: $colors-brand-400;
}
