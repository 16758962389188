.graphContainer {
  width: 50%;
  height: 100%;
  float: left;
  border-right: 1px solid #e0e0e0;
}

.tableContainer {
  width: 50%;
  float: right;
}

.graphContainer .blockedUsers {
  border-top: 1px solid #e0e0e0;
}

.tableContainer .dataTable-table {
  overflow-y: scroll;
  height: 90vh;
}

.link-button:hover {
  text-decoration: underline;
  color: #2c5e83;
  cursor: pointer;
}

.link-button {
  color: #3194d9;
}
