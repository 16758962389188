.tableheader-buttons > div > a {
  margin-right: 20px;
}

#files {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#drop_zone {
  height: calc(100% + 135px);
  position: absolute;
  width: 100%;
  margin-top: -86px;
  margin-left: -15px;
  z-index: 100;
  background: white;
}

#drop_zone .dropBorder {
  margin-top: 10px;
  margin-left: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  border-radius: 15px;
  border: 5px dashed lightgrey;
  text-align: center;
  display: table;
}

#drop_zone .dropContent {
  display: table-cell;
  vertical-align: middle;
  font-size: 25px;
  color: lightgray;
}

.importingJob {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}

.importingJobContainer {
  background: white;
  width: 200px;
  height: 150px;
  margin: 10vh 50%;
  text-align: center;
  position: relative;
}

.importingCancel {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 10px;
}

.importingJobStatus {
  padding-top: 20px;
  font-size: 20px;
  color: #848484;
}

.file > * {
  display: inline;
}

.file {
  background-color: whitesmoke;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
}

.fileInfo {
  float: right;
}

.fileInfo > * {
  display: table-cell;
  cursor: default;
  text-align: right;
  padding-right: 30px;
}

div.list {
  margin-top: 10px;
  border: 1px solid lightgrey;
  padding: 5px;
  margin-bottom: 10px;
}

div.fileHeader {
  border-bottom: 1px solid lightgrey;
}

#files {
  margin-bottom: 15px;
}

.loadingEntries {
  padding-right: unset;
  text-align: left;
  width: 100px;
}

.importErrors i {
  color: #ffd971;
  cursor: pointer;
  margin-left: 3px;
}

.importErrors i:hover .errorTooltip {
  visibility: visible;
}

.errorTooltip {
  text-align: left;
  visibility: hidden;
  width: 200px;
  background: #666;
  position: absolute;
  z-index: 200;
  padding: 5px;
  color: white;
  border-radius: 3px;
  margin-left: 25px;
  margin-top: -17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.errorTooltip i {
  color: #666;
  position: absolute;
  margin-left: -14px;
  font-size: 30px;
  margin-top: -10px;
}

.fileInfo .deleteButton {
  cursor: pointer;
  padding-right: unset;
  color: red;
}
