.errorMessage {
    color: red;
}

.deleteImage {
    position: absolute;
    margin-left: -7px;
    color: red;
    font-size: 20px;
    cursor: pointer;
}

.uploaded_image_template {
    margin-top: 10px;
}

.blockpage-active-block .label {
    display: inline;
    margin-right: 10px;
}

.blocked-image-container input {
    margin-top: 10px;
}

.blocked-message-container textarea{
    max-height: 500px;
    height: 100%;
}

.blocked-logout-container > .formgroup-element > .formgroup-element-title {
    width: 150px;
}

.blocked-logout-container > .formgroup-element > .formgroup-element-fields {
    margin-left: -100px;
}

.blocked-logout-container > .errorMessage {
    white-space: pre-wrap;
    line-height: 1.6;
}