.permission-status {
    display: table;
    margin: 20vh auto auto;
}

.permission-status .status-text {
    margin-top: 10px;
    font-size: 20px;
    color: grey;
}

.permission-status i {
    display: table-row;
    font-size: 40px;
    color: lightgrey;
}

.main-body-app {
    width: 100%;
}

.root_sidebar {
    width: 65px;
}