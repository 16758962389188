.clever_logo {
    width: 100px;
    margin-top: -15px;
    margin-bottom: -15px;
}

.clever_login {
    border-radius: 3px;
}

.loginCover {
    width: 207px;
    height: 48px;
    position: absolute;
    border-radius: 3px;
    background-color: unset;
}

.loginCover:hover {
    background-color: rgba(255, 255, 255, 0.2);;
}

.loginCover:active {
    background-color: rgba(0, 0, 0, 0.2);;
}

#district_id_input{
    width: 320px;
}

#school_id_input{
    width: 320px;
}

.school_selector{
    width: 320px;
}

.clever_sync_button{
    display: inline-block;
}