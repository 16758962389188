// CustomHeaders buttons are currently being forcefully styled by selector 'modal-body button', this mixin helps to specify which properties should be overriden
@mixin override-modal-button-styling(
    $background-color, 
    $color,
    $padding,
    $margin,
    $border-radius
) {
    background-color: $background-color !important;
    color: $color !important;
    padding: $padding !important;
    margin: $margin !important;
    border-radius: $border-radius !important;
}

.CustomHeaders_topBar {
    display: flex;
    align-items: center;
    margin: 0; // margin reset
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.CustomHeaders_topBarTitle {
    margin: 0; // margin reset
    font-size: $sub-title-font-size;
    font-weight: $text-bold-weight;
}

.CustomHeadersTopBar_addBtn {
    @include override-modal-button-styling(
        $background-color: #0075DB,
        $color: white,
        $padding: 4px 8px,
        $margin: 0 0 0 auto,
        $border-radius: 6px
    );

    border: none;
    outline: none;

    // icon
    span {
        margin-right: 6px;
    }
}

.CustomHeaders_table {
    display: table;
    width: 100%;

    padding: 10px 12px;
    border-radius: $container-border-radius;
    background: $container-grey-background;
    border: 1px solid $container-border-grey;

    font-size: 14px;

    thead {
        border-bottom: 1px solid $container-border-grey;
        background: white;
        tr {
            background: white;
        }
    }

    th {
        padding: 6px 12px;
        text-align: left;
        width: 50%;
    }

    td {
        padding: 6px 12px;
    }

    tr {
        background: rgba(255, 255, 255, 0.6);
        &:nth-child(even) {
            background: rgba(244, 245, 247, 0.6);
        } 
    }
}

.Customheaders_inputRow td {
    padding-top: 10px;
}

.CustomHeaders_input {
    width: 100%;
    background: $light-input-background;
    border: $light-input-border;
    border-radius: $container-border-radius;
    padding: 4px;
}

.CustomHeaders_inputSaveBtn {
    display: flex;
    height: 100%;
    
    button {
        @include override-modal-button-styling(
            $background-color: white,
            $color: #0075DB,
            $padding: 4px 8px,
            $margin: 0 0 0 auto,
            $border-radius: 6px
        );
        border: none;
        box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.05);
        border-radius: 6px;
        height: 100%;
    }
}

.CustomHeaders_removeEntryBtn {
    @include override-modal-button-styling(
        $background-color: unset,
        $color: #6B778C,
        $padding: 4px 8px,
        $margin: 0 0 0 auto,
        $border-radius: 6px
    );
    border: none;
}