.announcement-bar-container{
	width: 100%;
	font-size: $sub-title-font-size;
}

.status-bar-open{
	animation: 1s fadeIn;
	animation-fill-mode: forwards;
	text-align: center;
	padding: 5px 0px 5px 0px;
	align-items: center;
	display: flex;
	justify-content: center;
	background-color: #E6F5FF;
	color: black;
}

@keyframes fadeIn {
	from {
	  opacity: 0;
	  height: 0px;
	}
	to {
	  opacity: 1;
	  height: 50px;
	}
}

.announcement-icon{
	margin-left: auto;
	float: right;
}

.announcement-type{
	margin-left: auto;
	font-weight: 600;
	float: right;
}

.announcement-spacer{
	margin-left: 10px;
	float: right;
}

.announcement-title{
	margin-left: 10px;
	float: right;
}

.announcement-name{
	margin-left: 20px;
	float: right;
}

.announcement-link{
	cursor: pointer;
	border: none;
	outline: none;
	border-radius: 6px;
	padding: 6px 12px 6px 12px;
	margin-left: 30px;
	float: right;
	background-color: $close-button-blue-link;
	color: white;
	font-size: 14px;

	&:hover, &:focus {
		margin-left: 30px;
		float: right;
		color: white;
		background-color: $close-button-blue-hover-link;
	}
}

.ab-close-button {
    transform: translate(-20%, 0%);
	float: right;

    cursor: pointer;
    padding: 8px;
    border: none;
    outline: none;
	border-radius: 50%;
	margin-left: auto;
	background-color: $close-button-blue;

    &:hover, &:focus {
		background-color: $close-button-blue-hover;
    }

    img {
        width: 12px;
		display: block;
    }
}