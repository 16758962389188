.sis-add-new-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .sis-add-new-modal-content-info {
        margin-bottom: 8px;
    }
}

.sis-add-new-provider-icons-container {
    display: flex;
    justify-content: center;
    
    .sis-provider-icon-button {
        margin-right: 12px;
    }
}

.sis-provider-icon-button {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    padding: 8px 12px;
    border: 1px solid whitesmoke;
    border-radius: 6px;

    // Override initial button css
    outline: none;
    background-color: initial;

    &:hover {
        background-color: #F4F4F4;
        border-color: #1987D6;
    }
}

.sis-sync-details {
    :first-child {
        margin-right: 4px;
    }
}

.sis-delete-dialog-content {
    @extend .flex-row-center;

    .mui-checkbox {
        margin: 0;
    }
}