.studentRiskName{
    font-size: 14px;
    padding-top: 15px;
}

.studentRiskIndicators{
    font-size: 14px;
}

.smallStudentRiskName{
    margin-top: 8px;
}

.newUiRiskIndicatorButton {
    display: flex;
    align-items: baseline;
}
