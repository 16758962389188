.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    padding: 1px;
    cursor: pointer;
    margin-right: 30px;
}

.switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.switch-panel {
    display: block;
    height: inherit;
    width: inherit;
    background: #f1f1f1;
    border-radius: 3px;
}

.switch-input:checked ~ .switch-panel {
    background: #90E070;
}

.switch-handle {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 3px;
    background: white;
}

.switch-input:checked ~ .switch-handle {
    left: 21px;
}