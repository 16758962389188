html,
.app-root,
#surfwize-web {
  height: 100vh;
  width: 100%;
}

html {
  height: 100vh;
}

.app-root {
  height: 100%;
}

body.surfwize-body {
  margin: 0;
  padding: 0;
  height: auto;
  background: #f0f0f0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -ms-content-zooming: none;
  font-family: $default-font-family;
  font-size: $default-font-size;
  color: $text-color;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex {
  display: flex;
}

.flex-inline {
  display: inline-flex;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-align-center {
  align-items: center;
}

input.form-input {
  background: $input-background-color;
  border-radius: 2px;
  border: $text-input-border;
  padding: 8px 12px;
  width: 100%;
  &:focus {
    outline: none;
  }
}

input.pw-text-security {
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

button.large {
  width: 100%;
  background-color: $button-color;
  color: $white;
  text-transform: uppercase;
  border: 1px solid $button-color;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  &:focus {
    outline: none;
  }
}

button.large-disabled {
  @extend .large;
  opacity: 0.08;
}

/*extracted all various title classes for consistency, later can be unified*/
.formgroup_title {
  margin: 0;
  border-bottom: $title-border;
}

img.warning_title {
  margin: 20px 10px 20px 10px;
}

.formgroup_login_url {
  display: flex;
  padding-top: 10px;
  font-size: $default-font-size;
  font-weight: bold;
  justify-content: left;

  a {
    padding-left: 5px;
    cursor: pointer;
  }
}

.copy_to_clipboard_icon {
  transform: translate(-20%, 0%);
  float: right;

  cursor: pointer;
  padding: 2px;
  border: none;
  outline: none;
  margin-left: 8px;
  background-color: $invisible;

  img {
    width: 12px;
    display: block;
  }
}

p.warning_title {
  @include title-text;
  font-size: $sub-title-font-size;
}

.formgroup_title_title {
  @include title-text;
  margin: 20px 20px;
  font-size: $sub-title-font-size;
}

.formgroup_description {
  @include title-text;
  margin: 20px 20px;
}

.formgroup_small_subtitle {
  @include title-text;
  margin: 20px 0px;
  font-size: $sub-title-small-font-size;
}

.pageheader-h4 {
  padding-left: 10px;
  margin: 10px 0;
  line-height: 20px;
  text-rendering: optimizelegibility;
  font-size: $page-title-font-size;
}

h2 {
  font-size: $page-title-font-size;
  font-weight: 500 !important;
  padding-bottom: 0px;
}

.formgroup-content {
  margin: 10px 25px 30px;
  line-height: 1.2;
  color: $text-color;
  max-width: 800px;
  div.pw_info {
    @include message-info;
  }
  div.pw_error {
    @include message-error;
  }
  div.pw_ready {
    @include message-ready;
  }
  a.pw_hidden {
    display: none;
  }
}

h3 {
  font-size: $page-title-font-size;
  font-weight: 500 !important;
  padding-bottom: 0px;
}

.letter-panel-header {
  border-bottom: 1px solid #e2e2e2;
  padding: 20px;
  font-size: $page-title-font-size;
}

/** links style**/
.formgroup-element {
  a {
    cursor: pointer;
    text-decoration: none;
  }
}

/**button styles **/
.letter-panel-footer-save-button {
  font-weight: 600;
  text-transform: uppercase;
  color: $white;
  padding: 0 15px;
  border: none;
  line-height: 36px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  white-space: nowrap;
  font-size: $default-font-size;
  letter-spacing: 0.04em;
  position: relative;
  overflow: hidden;
  float: right;
  margin-left: 10px;
}

.letter-panel-footer-save-changes {
  background-color: $button-active-background;
}

.letter-panel-footer-save-saved {
  background-color: $button-inactive-background;
}

.formgroup_title_rightlink {
  background-color: $button-active-background;
  border-radius: 3px;
  border: none;
  color: $white;
  cursor: pointer;
  font-size: $small-font-size;
  font-weight: 400;
  padding: 5px 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  margin: 20px 10px;
  float: right;

  &:hover {
    text-decoration: underline;
  }

  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}

.tableheader-button.button-selected {
  background-color: $button-active-background;
  color: $white;
  &:hover {
    text-decoration: underline;
  }
}

.blockedTable-buttons {
  margin-top: -5px;
}

.tableheader-button {
  background-color: $button-inactive-background;
  color: $text-color;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 10px;
  border-radius: 3px;
  display: inline-table;
}

.modal-footer,
.modal-body {
  button {
    @include modal-button;
  }
}

.AlertBanner {
  padding: 8px;
  font-weight: $small-font-size;
  margin-top: 4px;
  margin-bottom: 8px;
  border-radius: 3px;
}

.AlertBanner--error {
  @extend .AlertBanner;
  background-color: $fz-red-6;
}

.inline-block {
  display: inline-block;
  margin-right: 16px;
}

@mixin standard-pill {
  background: white;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.standard-pill {
  @include standard-pill;
}

.standard-pill-unselectable {
  @include standard-pill;
  cursor: default;
}

.link-disabled {
  pointer-events: none;
}

.link-text-lightened {
  color: scale-color($color: #337ab7, $lightness: $text-lighten-ratio);
}

.text-bold {
  font-weight: $text-bold-weight;
}

.text-disabled {
  color: scale-color($color: $text-color, $lightness: $text-lighten-ratio);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalized {
  text-transform: capitalize;
}

.text-centered {
  text-align: center;
}

.modal-form {
  & > *:not(label):not(:last-child) {
    margin-bottom: 12px;
  }

  // Style SearchableSelect with the same background color as the inputs
  .Select {
    .Select-control,
    .Select-control.is-focused,
    .Select-value,
    .Select-input {
      background: $input-background-color;
    }
  }
  max-height: calc(100vh - 350px) !important;
}

.modal-form-overflow {
  max-height: calc(100vh - 350px) !important;
  overflow-y: auto;
}

// intended for use with icons that are same/very similiar size to the label
.label-with-small-icon-left {
  :first-child {
    margin-right: 6px;
  }
}

div.error-with-list {
  color: $universal-red;
}

.tableHeaderWrapper {
  display: inline-flex;
  align-items: center;
}
