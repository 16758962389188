.search-input {
	display: inline-block;
	border-radius: 12px;
	border: $light-grey-border;
	padding: 6px;
	padding-left: 8px;
	background-color: white;
	order: 2;
	width: 100%;
	margin-bottom: 10px;

	span {
		margin-right: 6px;
	}

	input {
		font-size: $default-font-size;
		width: 90%;
		background-color: unset;
		line-height: 1;
		padding: 0;
		border: none;
		outline: none;
	}
}

.search-results {
	margin-top: 5px;
	border-radius: 7px;
	border: $light-grey-border;

	button {
		background: #ECEBEA;
		padding: 8px;
		width: 100%;
		text-align: left;
		border: 0;
		
		&:hover, &:focus {
			background-color: $button-active-background;
			color: white;
			border: none;
		}
	}

	.search-result {
		padding: 8px;
		border-bottom: $light-grey-border;
		cursor: pointer;

		
		&:hover, &:focus {
			background: #E7F9FF;
		}
	}
}