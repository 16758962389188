@mixin normal-font-weight{
	font-weight: 400;
}

@mixin bold-font-weight{
    font-weight: bold;
}

@mixin link-as-button {
    display: block;
    padding: 7px 12px;
    background-color: $button-color;
    color:$white;
    margin-top: 10px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;
}

@mixin modal-button {
    background-color: $button-active-background;
    color: $white;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    border-radius: 3px;
    &:active, &:focus, &:hover{
        background-color: $button-active-background;
        color: $white;
    }
}

@mixin title-text {
    display: inline-block;
    font-weight: 450;
}

@mixin message-info {
    font-size: 12px;
    a {
        font-weight: bold;
    }
    span {
        margin-left: 5px;
    }
}

@mixin message-success {
    @include message-info;
    color: $success-text-color;
}

@mixin message-ready {
    @include message-info;
}

@mixin message-error {
    @include message-info;
    color: $error-text-color;
}
