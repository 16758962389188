.chart-container {
  width: 100%;
  height: 300px;

  &.has-centered-elements {
    display: flex;
    justify-content: center;
    align-items: center;

    .is-centered {
      margin-left: 40%;
      width: 100%;
    }
  }
}

.chart {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-top: 16px;

  &.loading {
    opacity: 0;
  }
}

.chart-container--pie {
  height: 350px;
}

.line-chart-container {
  background-color: $white;
  margin: 20px 0 20px 0;
}

.activity-chart {
  font-size: 16px;
  a:hover {
    cursor: pointer;
  }
}
