.button {
    height: 32px;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 5px 20px 5px 20px;
    border: none;
    box-shadow: none;
    font-weight: bold;
    letter-spacing: 0.05em;
    transition: all .2s ease-in-out;
    cursor: pointer;
}


.button-primary {
    background-color: $universal-brand-blue;
    color: $white;
}

.button-primary:hover, .button-primary:focus, .button-primary:active {
    background-color: lighten($universal-brand-blue, 10%);
    border: none;
}

.button-secondary {
    background-color: $button-active-background;
    color: $white;
}

.button-secondary:hover, .button-secondary:focus, .button-secondary:active {
    background-color: lighten($button-active-background, 10%);
    border: none;
}

.button:disabled {
    background: $button-inactive-background;
    color: $white;
}


.smallHelpIcon{
    border:none;
    border-radius: 50%;
    background-color: $button-color;
    width: 12px;
    height: 12px;
    font-size: 8px;
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;

    i {
        color:$white;
    }
}

.modern-button {
    font-size: 16px;
    border: none;
    box-shadow: none;
    outline: none;
    font-weight: bold;
}

.modern-button--small {
    @extend .modern-button;
    padding: 8px 16px;
    border-radius: 6px;
}

.modern-button--primary {
    @extend .modern-button;
    color: white;
    background-color: $universal-brand-blue;

    &:hover {
        background-color: scale-color($color: $universal-brand-blue, $lightness: 5%);
    }
}

.modern-button--disabled {
    @extend .modern-button;
    background-color: #CFCFCF;
    pointer-events: none;
}
