.Dashboard_headerBtnsRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0px;

  button {
    margin-right: 8px;
  }

  button:last-child {
    margin-right: 0;
  }
}

.ClasswizeLoginModal_btn {
  width: 100%;
}

.personaDashboardPage {
  padding: 32px;
}

.personaDashboardRow {
  padding-bottom: 16px;
  overflow: hidden;
}

.personaDashboardCol {
  padding-left: 0px;
  padding-top: 10px;
}

.personaDashboardHeader {
  padding-right: 10px;
  padding-bottom: 10px;
}

.personaDashboardHeader-h4 {
  line-height: 20px;
  text-rendering: optimizelegibility;
  font-size: $page-title-font-size;
  display: inline-block;
}

.personaCardRow {
  margin-left: 2px;
  padding-bottom: 16px;
}

.personaCardCol {
  padding: 0;
  padding-right: 16px;
}

.personaModule {
  display: flex;
  background: #fff;
  flex-direction: column;
  padding: 16px;
  height: 380px;
}

#topSixAppsModule {
  position: relative;
  z-index: 1;
}

.personaMiniCardsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 380px;
}

.personaMiniCardsRow {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  height: 50%;
}

.personaMiniCard {
  display: flex;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  padding: 20px 8px;
  height: 100%;
  width: 50%;
  align-items: center;

  .cardTotal {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .cardTitle {
    display: flex;
    color: #51595d;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;
    img {
      margin-left: 4px;
    }
  }

  .cardLink {
    align-items: center;
  }
}

.personaModule-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  img {
    margin-left: 8px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 0px;
  }
}

.personaModule-rightLink {
  margin-left: auto;
  font-size: 14px;
  color: #0075db;
  font-weight: bold;
}

.personaModule-centerLink {
  margin-left: auto;
  font-size: 14px;
  color: #0075db;
  font-weight: bold;
}

.personaModule-buttonGroup {
  padding-bottom: 15px;
}

.trendingItemPill {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 6px;
  align-items: center;
}

.trendingItemPill:first-child {
  margin-top: 6px;
}

.trendingItemPill:hover {
  background: #e7f9ff;
  border-radius: 3px;
  cursor: pointer;
}

.trendingItemPill .fa {
  font-size: 12px;
  color: #51595d;
}

.redFlagContainer {
  white-space: nowrap;
  padding-left: 20px;
  flex-grow: 1;
  width: 80%;
}

.redFlagItemPill {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: -5px auto;
}

.redFlagItemPill-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0 0 0.2em;
  cursor: pointer;
  width: 20%;
  max-width: 150px;
}

.personaRedFlagsToggles {
  margin-left: 16px;
  margin-right: 4px;
}

.personaTopBlockedToggles {
  margin-left: 16px;
  margin-right: 4px;
}

.trendingItemPill-text {
  margin-left: 8px;
  margin-right: 4px;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.trendingItemPill-percent {
  display: flex;
  margin-left: auto;
  border-radius: 30px;
  background: #ecebea;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: row;
  align-items: center;
}

.trendingItemPill-percent--flagged {
  background: #ffdde1;
}

.trendingItemPill-icon {
  height: 16px;
}

.trendingItemPill-icon.trendingItemPill-icon-svg {
  filter: invert(35%) sepia(9%) saturate(407%) hue-rotate(155deg) brightness(92%) contrast(92%);
}

.trendingArrow-icon {
  padding-right: 4px;
}

.personaModule-tooltip {
  overflow: visible;
  position: relative;

  .personaModule-tooltipText {
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: #3e3e3c transparent transparent transparent;
    }

    visibility: hidden;
    display: flex;
    align-items: center;
    width: 200px;
    color: white;
    text-align: center;
    padding: 10px;
    position: absolute;
    z-index: 1000;
    bottom: 150%;
    left: 50%;
    margin-left: -100px;
    margin-bottom: 10px;
    background: #3e3e3c;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;

    i {
      margin: 0 8px;
    }
  }

  &:hover {
    .personaModule-tooltipText {
      visibility: visible;
    }
  }
}

.riskStudentIndicatorText {
  font-size: 14px;

  img {
    margin-top: -0.25em;
    margin-right: 8px;
  }

  i {
    margin-right: 8px;
  }

  svg {
    margin-right: 8px;
  }
}

.persona-date-range {
  padding: 8px;
  background: #ffffff;
  border: 1px solid #51595d;
  box-sizing: border-box;
  border-radius: 5px;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 1px;

  &.active,
  &:hover {
    border: 1px solid #0075db;
  }

  i {
    margin: 0 8px;
  }
}

.daterangepicker {
  .ranges li.active {
    color: #080707;
    background: #c7ecff;
  }

  select {
    background: #ffffff !important;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
  }

  .drp-calendar td {
    &.active {
      background: #0075db;
    }
  }

  .drp-buttons {
    .drp-selected {
      float: left;
      padding-top: 0.5em;
    }

    button {
      width: 82px;
      height: 24px;
      left: 344px;
      top: 8px;
      border-radius: 3px;
      box-sizing: border-box;

      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.05em;

      &.applyBtn {
        background: #0075db;
        color: #f5f5f5;
        border: 0;
      }

      &.cancelBtn {
        background: #ffffff;
        border: 1px solid #0075db;
        color: #343434;
      }
    }
  }
}

#personaTopSixAppsChart {
  width: 100%;
}

#personaTopSixAppsChartLegend {
  width: 100%;
  color: #51595d;
}

#personaTopSixAppsChartLegend .legend-item {
  cursor: default;
  width: 50%;
  float: left;
  margin: 4px 0;
  font-size: 14px;
  font-weight: normal;
}

#personaTopSixAppsChartLegend .legend-item-single-column {
  width: 100%;
}

#personaTopSixAppsChartLegend .legend-marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  float: left;
  margin: 4px 6px 0 0;
}

#personaTopSixAppsChartLegend .legend-marker-inner {
  margin-left: 6px;
}

#personaTopSixAppsChartLegend .legend-label {
  max-width: 46%;
  white-space: nowrap;
  overflow: hidden;
  float: left;
  text-overflow: ellipsis;
}
#personaTopSixAppsChartLegend .legend-value {
  float: right;
}
#personaTopSixAppsChartLegend .legend-value-inner {
  margin-right: 6px;
}

.personaTopBlockedChart {
  height: 100%;
}

.centered {
  top: 50%;
  margin: auto;
}

.nodata {
  font-weight: 400;
  font-size: 12px;
  color: #42526e;
}

// amCharts doesn't render properly (or at all) if it is hidden and then shown.
// This just moves the chart offscreen when it is not supposed to be shown (when loading or when there's no data)
.hidePersonaChart {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.newBadge {
  display: inline-flex;

  width: 36px;
  height: 18px;
  border-radius: 4px;
  border: 1px #0bdbce;
  background-image: linear-gradient(#0bdbce, #95f1eb);
  color: #091e42;
  font-family: Roboto;
  font-weight: 500;
  font-size: 10px;
  margin-left: 12px;
  padding-left: 6px;
  padding-top: 3px;
  margin-bottom: -2px;
}

.newBadgeDashboard {
  transform: translateY(-20%);
}
