.form-element-inactive {
  padding: 8px;
  background-color: $form-inactive-color;
  border-radius: 4px;
  display: inline-block;
}

.form-element-inactive-text {
  margin-left: 8px;
}

.form-element-info-circle {
  color: #00998f;
}
