.MITMButton > i {
  color: orange;
}

.tag-selector .Select-placeholder {
  background-color: whitesmoke;
}

.tag-selector .Select-noresults {
  padding: unset;
}

.tag-selector .addCustomTag {
  color: black;
  cursor: pointer;
  padding: 8px 10px;
}

.tag-selector .addCustomTag:hover {
  background-color: rgba(0, 126, 255, 0.1);
}

.tag-selector .addCustomTag i {
  color: green;
}

.tag-selector-group {
  background-color: whitesmoke;
  float: left;
  width: 100%;
}

.tag-selector {
  padding-bottom: 15px;
  width: 300px;
  padding-top: 8px;
  margin-left: 15px;
  float: left;
  margin-right: 19px;
}

.tag-selector .Select-menu-outer {
  margin-top: -15px;
}

.delete-groups {
  float: left;
  margin-left: 15px;
  cursor: pointer;
  width: 25px;
  margin-top: 10px;
}

.delete-groups:hover {
  color: red;
}

.Select.tag-selector .Select-control {
  background-color: whitesmoke;
}

.select-groups .select-group {
  padding: 6px 12px;
  background-color: #2188d0;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  width: fit-content;
  margin-right: 7px;
}

.select-groups {
  padding: 4px;
  display: table;
  margin-top: 8px;
  user-select: none;
}

.select-group:hover {
  background-color: #22adfe;
}

.select-group .label {
  float: left;
  margin-right: 5px;
}

.select-group:hover i {
  color: red;
}

.select-groups .select-group-reset {
  padding: 6px 12px;
  background-color: #cdcdcd;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  width: fit-content;
  margin-right: 7px;
}

.select-group-reset .label {
  float: left;
  margin-right: 5px;
}

.select-group-reset:hover {
  background-color: gray;
}

.progressBar {
  background: #1987d5;
  height: 20px;
  border-radius: 10px;
  float: left;
}

.progressBar-alarm {
  background: red !important;
  height: 20px;
  border-radius: 10px;
  float: left;
}

.hitsText {
  float: left;
}

#downshift-0-menu {
  /*
  This is used for the dropdown menu, so that it appears on top of the wordcloud. It appears that when the wordcloud has
  a background colour applied, it has a weird effect with the layering of divs causing the menu to appear behind the
  wordcloud background.
  */
  z-index: 1;
  background-color: white; /* The menu select options border is otherwise transparent. */
}
