.card {
  background: $card-background-color;
  font-family: $default-font-family;
  font-size: $large-font-size;
  margin-left: 25px;
  margin-bottom: 25px;
}

.card-content {
  padding: 20px 16px 0 16px;
}

.card-actions {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  font-size: $default-font-size;

  a {
    cursor: pointer;
  }
}

.card-content p {
  margin-bottom: 7px;
}

.card-title {
  font-weight: $text-bold-weight;
  font-size: $large-font-size;
}

@media screen and (min-width: 40em) {
  .card {
    flex: 0 1 calc(50% - 1em);
  }
}

@media screen and (min-width: 70em) {
  .card {
    flex: 0 1 calc(30% - 1em);
  }
}
