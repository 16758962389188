#accountSettings {
    .message-content {
        margin: 10px 25px;
    }
    .warning {
        display: flex;
        align-items: center;
        color: #ff0000;
        padding: 10px 0;
        font-size: 12px;
        margin-left: -4px;

        img {
            margin-right: 6px;
        }
    }

    .well {
        display: flex;
        padding: 10px 15px;
        background-color: #f5f5f5;
        border-radius: 5px;
        border: #ebebeb;
        width: fit-content;
        align-items: center;

        .googleLogo {
            width: 22px;
            height: 22px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../../images/logo_google.svg');
            display: inline-block;
            margin-right: 8px;
        }
        .title {
            font-size: 12px;
        }
        .username {
            font-size: 10px;
            margin-bottom: 2px;
        }
    }

    .account-phone-number {
        max-width: 400px;
    }
}
