$white: #fff;
$universal-red: #d00000;
$fz-red-6: #ffe5e7;
$universal-brand-blue: #1987d6;
$text-color: #000;
$text-bold-weight: bold;
$text-lighten-ratio: 50%;
$input-background-color: #eaeaea;
$text-input-border: 1px solid #e7e7e7;
$title-border: 1px solid #e2e2e2;
$thick-border: 2px solid #e7e7e7;
$input-background-color: #f7f7f7;
$text-input-radius: 2px;
$button-color: #1987d6;
$error-text-color: $universal-red;
$success-text-color: #008000;
$text-disabled-color: #a5a6a7;
$foundation-blue: #0075db;
$error-input-border-thick: 2px solid $universal-red;
$error-input-border: 1px solid $universal-red;
$tr-height: 60px;
$default-font-family: "lato";
$small-font-size: 12px;
$default-font-size: 14px;
$large-font-size: 16px;
$page-title-lage-font-size: 36px;
$page-title-font-size: 24px;
$sub-title-font-size: 18px;
$sub-title-small-font-size: 16px;
$sub-title-font-weight: 450;
$button-active-background: #666;
$button-inactive-background: #cdcdcd;
$navbar-menu-item-heighlight: whitesmoke;
$link-color: $universal-brand-blue;
$close-button-blue: rgba(25, 135, 214, 0.25);
$close-button-blue-hover: rgba(13, 125, 206, 0.5);
$close-button-blue-link: #1987d6;
$close-button-blue-hover-link: #0d7dce;
$invisible: rgba(0, 0, 0, 0);
$card-background-color: #f0f0f0;
$light-grey-border: 1px solid #dbdbdb;

$form-inactive-color: #e3faf8;

$container-border-radius: 6px;
$container-border-grey: #ebecf0;
$container-grey-background: rgba(244, 245, 247, 0.6);
$light-input-background: $white;
$light-input-border: 1px solid #c1c7d0;

// Component Library colours

$colors-neutrals-60: #b3bac5;
$colors-neutrals-700: #253858;

$colors-text-link-hover: #0075db;

$colors-brand-400: #0075db;

$colors-input-outline-error: #f4767f;
$colors-input-background-error: #ffe8ea;
