// customize rc-tooltip lib
.rc-tooltip-content {
    width: 152px;
}

.rc-tooltip-inner {
    color: $white;
    padding: 8px 12px;
    background-color: $colors-neutrals-700;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: $colors-neutrals-700 !important;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: $colors-neutrals-700 !important;
}