$badge-error-background-color: #DF2B20;
$badge-error-text-color: $white;

$badge-warning-background-color: #E76017;
$badge-warning-text-color: $white;

$badge-background-color: #F0F0F0;
$badge-text-color: $text-color;

$badge-font-size: 10px;
$badge-width: 78px;
$badge-height: 28px;
$badge-border-radius: 30px;
$badge-padding: 7px 13px 9px 13px;

@mixin badge {
    width: $badge-width;
    height: $badge-height;
    background: $badge-background-color;
    border-radius: $badge-border-radius;
    padding: $badge-padding;
    font-family: $default-font-family;
    font-style: normal;
    font-weight: $text-bold-weight;
    font-size: $badge-font-size;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
    color: $badge-text-color;
    text-transform: uppercase;
} 

@mixin badge-blocked {
    @include badge;
    background: $badge-error-background-color;
    color: $badge-error-text-color;
}

@mixin badge-flagged {
    @include badge;
    background: $badge-warning-background-color;
    color: $badge-warning-text-color;
}