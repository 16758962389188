.searchable-select .Select-control {
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.searchable-select .Select-arrow {
  margin-right: -19px;
}

.searchable-select {
  min-width: 80px;
}

.searchable-select .Select-placeholder {
  padding-left: 0;
}

.searchable-select .Select-input {
  padding-left: 0;
}

.searchable-select .Select-menu .VirtualizedSelectOption {
  cursor: pointer;
}

.searchable-select .Select-menu-outer {
  z-index: 999;
}
