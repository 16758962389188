.base-modal-backdrop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 1040;
  background-color: rgba($color: black, $alpha: 0.5);
}

.base-modal-hide {
  display: none;
}

.base-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;

  :hover {
    cursor: pointer;
  }

  .modal-close-icon {
    display: block;
  }
}

$modal-padding: 32px;
.base-modal {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 1050;

  min-width: 400px;
  max-width: 700px;

  padding: $modal-padding;

  background-color: white;
  border-radius: 3px;

  .base-modal-buttons {
    margin-top: 32px;
  }
}

.no-padding {
  padding: 0;
}

.base-modal-buttons {
  display: flex;
  justify-content: flex-end;

  & > * {
    margin: 0;
  }

  :last-child {
    margin-right: 0;
  }
}

.base-modal-buttons-centered {
  justify-content: center;
}

.modal-button {
  @include modal-button;
}

.modal-title--icon {
  font-size: 22px;
  margin-bottom: 16px;
}

.sleek-modal-title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 32px;
}

.sleek-modal-description {
  text-align: center;
  margin-bottom: 24px;
}

.sis-log-modal {
  min-width: 200px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sis-log-modal-content {
    .sis-log-modal-block {
      max-width: 400px;
      text-overflow: ellipsis;
      padding-left: 20px;
      margin-right: 20px;
      margin-bottom: 16px;

      &.error {
        color: $error-text-color;
      }
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}

.sis-delete-modal {
  .sleek-modal-title {
    padding: 0 25px; // force wrapping as currently only the last word in title wraps
  }
}

$scrolling-modal-padding: 16px;
.scrolling-modal-backdrop {
  @extend .base-modal-backdrop;
  padding-top: 6vh;
}

.scrolling-modal-hide {
  @extend .base-modal-hide;
}

.scrolling-modal-close {
  @extend .base-modal-close;
  top: 24px;
  right: 24px;
}

.scrolling-modal {
  @extend .base-modal;
  display: flex;
  flex-direction: column;
  padding: $scrolling-modal-padding;
  max-height: 88vh;
  width: 600px;
  min-width: none;
  max-width: none;

  .scrolling-modal-buttons {
    margin-top: $scrolling-modal-padding;
  }
}

.scrolling-modal-title {
  text-align: left;
  margin-top: 0;
  margin-bottom: $scrolling-modal-padding;
}

.scrolling-modal-content {
  overflow-y: auto;
  margin-inline: -$scrolling-modal-padding;
  padding-inline: $scrolling-modal-padding;
  border-block: 0.5px solid #ddd;
  padding-block: 18px;
}

.scrolling-modal-buttons {
  @extend .base-modal-buttons;
}

.scrolling-modal-buttons-centered {
  @extend .base-modal-buttons-centered;
}

.chakra-modal__overlay {
  opacity: 0.6 !important;
}

.chakra-modal__content-container {
  z-index: 100;
  section {
    overflow: hidden;
  }
}

.included-networks-form {
  min-height: 200px;
}
