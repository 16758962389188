.riskStudentPill {
  border-top: 1px solid lightgrey;
  height: 20%;
}

.riskStudentName {
  display: table-cell;
  float: left;
  min-width: 120px;
  max-width: 50%;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #3194d9;
}

.riskStudentName:hover {
  text-decoration: underline;
  color: #2c5e83;
}

.riskStudentIndicators {
  white-space: nowrap;
  overflow-x: auto;
}

.riskStudentIndicator {
  display: inline-block;
  border-radius: 3px;
  margin: 10px 5px;
  color: white;
  cursor: default;
}

.smallRiskStudentIndicator {
  display: inline-block;
  border-radius: 3px;
  color: #fff;
  cursor: default;
  margin: 5px 5px 0px 0px;
}

.indicatorSelectable {
  cursor: pointer;
}

.indicatorSelectable.riskStudentIndicator-high:hover {
  background: #e02f42;
  text-decoration: none;
  color: white;
}

.indicatorSelectable.riskStudentIndicator-medium:hover {
  background: #fdeeee;
  color: #c5002f;
  border: 1px solid #cd2638;
  box-sizing: border-box;
  border-radius: 3px;
  text-decoration: none;
}

.indicatorSelectable.riskStudentIndicator-low:hover {
  border: 1px solid #0c68a9;
  color: #195682;
  box-sizing: border-box;
  border-radius: 3px;
  text-decoration: none;
}

.indicatorSelectable.riskStudentIndicator-neutral:hover {
  text-decoration: none;
}

.riskStudentIndicatorText {
  margin: 5px 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.riskStudentIndicator-neutral {
  background: white;
  color: #42526e;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 3px;
}

.riskStudentIndicator-high {
  background: #c5002f;
  border-radius: 3px;
}

.riskStudentIndicator-medium {
  background: #fdeeee;
  border: 1px solid #c5002f;
  color: #c5002f;
  box-sizing: border-box;
  border-radius: 3px;
}

.riskStudentIndicator-low {
  border: 1px solid #195682;
  color: #195682;
  box-sizing: border-box;
  border-radius: 3px;
}

.keywordSearch_riskStudentIndicator-low {
  margin-right: 5px;
  filter: invert(27%) sepia(44%) saturate(1052%) hue-rotate(166deg) brightness(93%) contrast(91%);
}

.keywordSearch_riskStudentIndicator-medium {
  margin-right: 5px;
  filter: invert(8%) sepia(94%) saturate(5811%) hue-rotate(339deg) brightness(103%) contrast(107%);
}

.keywordSearch_riskStudentIndicator-high {
  margin-right: 5px;
  filter: invert(100%) sepia(66%) saturate(11%) hue-rotate(221deg) brightness(103%) contrast(100%);
}

.at-risk-student {
  height: calc(100% + 5px);
  margin-top: -5px;
}

.studentPillContainer {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 3px;
  margin-left: 5px;
  overflow-x: auto;
  overflow-y: hidden;
}

.riskStudentBusyLoader {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
