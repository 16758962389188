.deviceUpdateButton {
    width: 160px;
    background-color: #666;
    color:white;
}
.deviceUpdateButton:hover {
    background-color: #666;
    color:white;
}

.deviceRestartButton {
    width: 160px;
    background-color: #666;
    color:white;
}

.deviceRestartButton:hover {
    background-color: #666;
    color:white;
}

.updateLog {
    resize: none;
    width: 100%;
    height: 200px;
    overflow-x: auto;
    margin-top: 10px;
}