.EditObject_tooltipContainer {
    display: inline;
    position: absolute;
    align-self: center;
}

.EditObject_tooltipPopup {
    text-align: left;
    visibility: hidden;
    width: 18vw;
    background: #666;
    position: absolute;
    z-index: 200;
    padding: 5px;
    color: white;
    border-radius: 3px;
    margin-left: -9vw;
    top: 40px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;

    i {
        color: #666;
        position: absolute;
        font-size: 30px;
        top: -20px;
        left: 12vw;
    }

    &.visible {
        visibility: visible;
    }

    .EditObject_tooltipCode {
        padding:2px;
        margin-left: 10px;
        font-family: monospace;
        font-size: 12px;
        word-wrap: break-word;
    }

    .EditObject_tooltipText {
        padding: 2px;
    }
}




