.maxtable.user-journey-table td {
    width: auto !important;
}

.activity-cell {
    max-width: 300px;
    overflow-wrap: break-word;
}

.activity-favicon {
    width: 20px;
}

.stjr-filter-group {
    padding-bottom: 55px;
}

.stjr-filter {
    float: left;
}

.badge {
    @include badge;
}

.badge-blocked {
    @include badge-blocked;
}

.badge-flagged {
    @include badge-flagged;
}