/*navigation*/
$sub-nav-menu-background: #454a4f;
$root-sidebar-backgorund: #353b40;
$root-sidebar-link-selected-color: #5eabe2;
$root-sidebar-tooltip-background: #3d454d;
$root-sidebar-tooltip-border-color: #a2a4a7;
$sidenav-sublink-backgorund: #49494a;
$nav-subitem-background: #54585d;
$nav-subitem-hover-background: #64686e;

/*************************** Side navbar ***************************************/

.nav-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    background-color: $nav-subitem-hover-background;
  }
}

.nav-item-text {
  color: $white;
  font-size: $small-font-size;
  text-transform: uppercase;
  padding: 8px 0px 8px 15px;
  cursor: pointer;
  letter-spacing: 0.07em;

  &:hover {
    background-color: $nav-subitem-hover-background;
  }
}

.tooltip .tooltiptext {
  $tooltip-size: 54px;
  visibility: hidden;
  display: flex;
  align-items: center;
  max-width: 300px;
  min-width: 150px;
  background-color: $root-sidebar-tooltip-background;
  color: $white;
  text-align: left;
  padding: 5px 0 5px 10px;
  position: absolute;
  z-index: 1000;
  top: 4px;
  left: 65px;
  border: 1px solid $root-sidebar-tooltip-border-color;

  .fa.fa-caret-left {
    font-size: $tooltip-size;
  }

  i {
    font-size: $tooltip-size;
    position: absolute;
    color: $root-sidebar-tooltip-background;
    left: -17.5px;
    text-shadow: -1px -1px 0 $root-sidebar-tooltip-border-color, -1px 1px 0 $root-sidebar-tooltip-border-color;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
