.importLabel {
  margin: -15px;
  padding: 15px;
}

.csv-import-type-selector {
  padding: 10px;
}

#files_1 {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#files_2 {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#drop_zone_1 {
  height: calc(50% + 5px);
  position: absolute;
  width: 100%;
  margin-top: -60px;
  margin-left: -35px;
  z-index: 100;
  background: white;
}

#drop_zone_1.full_size {
  height: calc(100% + 135px);
}

#drop_zone_1 .dropBorder {
  margin-top: 10px;
  margin-left: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  border-radius: 15px;
  border: 5px dashed lightgrey;
  text-align: center;
  display: table;
}

#drop_zone_1 .dropContent {
  display: table-cell;
  vertical-align: middle;
  font-size: 25px;
  color: lightgray;
}

#drop_zone_1.drag_over .dropBorder {
  border: 5px dashed #7d90d3;
}

#drop_zone_1.drag_over .dropContent {
  color: #7d90d3;
}

#drop_zone_2 {
  height: calc(50% + 5px);
  position: absolute;
  width: 100%;
  margin-top: -5px;
  margin-left: -35px;
  z-index: 100;
  background: white;
}

#drop_zone_2.full_size {
  height: calc(100% + 135px);
  margin-top: -86px;
}

#drop_zone_2 .dropBorder {
  margin-top: 10px;
  margin-left: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  border-radius: 15px;
  border: 5px dashed lightgrey;
  text-align: center;
  display: table;
}

#drop_zone_2.drag_over .dropBorder {
  border: 5px dashed #7d90d3;
}

#drop_zone_2.drag_over .dropContent {
  color: #7d90d3;
}

#drop_zone_2 .dropContent {
  display: table-cell;
  vertical-align: middle;
  font-size: 25px;
  color: lightgray;
}

.importErrors i {
  color: #ffd971;
  cursor: pointer;
  margin-left: 3px;
}

.importErrors i:hover .errorTooltip {
  visibility: visible;
}

.errorTooltip {
  text-align: left;
  visibility: hidden;
  width: 200px;
  background: #666;
  position: absolute;
  z-index: 200;
  padding: 5px;
  color: white;
  border-radius: 3px;
  margin-left: 25px;
  margin-top: -17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.errorTooltip i {
  color: #666;
  position: absolute;
  margin-left: -14px;
  font-size: 30px;
  margin-top: -10px;
}

.errorItem {
  padding: 2px;
}

.tooltipContainer {
  display: inline;
  position: absolute;
}

.tooltipContainer i {
  color: grey;
  font-size: 20px;
  cursor: pointer;
  margin-left: 3px;
}

.tooltipContainer i:hover .tooltipPopup {
  visibility: visible;
}

.tooltipPopup {
  text-align: left;
  visibility: hidden;
  width: 200px;
  background: #666;
  position: absolute;
  z-index: 200;
  padding: 5px;
  color: white;
  border-radius: 3px;
  margin-left: 25px;
  margin-top: -17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.codeTooltip {
  margin-left: 10px;
  font-family: monospace;
  font-size: 12px;
}

.tooltipPopup i {
  color: #666;
  position: absolute;
  margin-left: -14px;
  font-size: 30px;
  margin-top: -10px;
}

.tooltipItem {
  padding: 2px;
}
