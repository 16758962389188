.loginpage-loginform {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25vh;
}

.loginpage-loginform .login-button {
    color: #FFFFFF;
    background-color: #1712B2;
}


.login-heading-logo > img {
    height: 50px;
    display: block;
    margin: 5px auto;
}

.login-heading-logo > div {
    font-size: 20px;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    display: flex;
    float: left;
    margin: 15px 0 0 100px;

}

.login-heading-logo {
    width: 400px;
}