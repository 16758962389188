.listselector-item i {
    margin-right: 5px;
    cursor: pointer;
}

.listselector-item {
    background-color: white !important;
    -webkit-border-radius: 0.25em !important;
    -moz-border-radius: 0.25em !important;
    border-radius: 0.25em !important;
    border: 1px solid #ccc !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    font-size: 14px !important;
    /* height: 44px !important; */
    padding: 0 0.8em !important;
    width: 100% !important;
    -webkit-appearance: none !important;
    color: #333 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-top: 10px !important;
    clear: both;
    padding-bottom: 10px !important;
}