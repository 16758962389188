.tableheader-buttons {
  /* flex child properties */
  order: 3;
  margin-left: auto;
  display: flex;
  align-items: center;
  align-content: center;
}

/* Overrides individual margins set on buttons in the table header, in LW-965 the tableheader-buttons container
now controls the margins - individual items shouldn't set their own margins */
.tableheader-buttons > * {
  margin: 0;
  margin-right: 6px;
}

.tableheader-buttons:last-child {
  margin-right: 0;
}

.StickyTableHeader {
  top: 0;
  position: sticky;
  background: $white;
  border-bottom: 1px solid gray;
  z-index: 10;
}

.maxtable {
  width: 100%;
  padding: 8px 8px;
  border-collapse: separate;
  background-color: white;
}

.maxtable > thead > tr {
  border-bottom: 1.75px solid #cfcfcf;
  height: $tr-height;
}

.maxtable > tbody > tr {
  height: $tr-height;
  &:hover {
    background-color: whitesmoke;
  }
}

.maxtable th {
  font-size: $sub-title-font-size;
  text-align: left;
  background: whitesmoke;
  font-weight: 500;
  background-color: inherit;
  padding: 10px;
}

.maxtable td {
  font-size: $default-font-size;
  padding: 10px;
  vertical-align: middle;
  border-bottom: solid 1px whitesmoke;
  background-color: inherit;
  cursor: default;

  a {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
  }

  span.error {
    display: inline-block;
    color: $error-text-color;
  }

  span {
    white-space: nowrap;
    vertical-align: middle;
  }

  span.label {
    text-transform: capitalize;
  }

  img {
    margin-right: 10px;
    vertical-align: middle;
  }

  .row-operations {
    .separator {
      margin-left: 6px;
      margin-right: 6px;
    }

    a.disabled {
      @extend .text-disabled;
      cursor: default;
    }

    a.destructive {
      color: $error-text-color;
    }
  }

  .row-operations-disabled {
    a {
      @extend .text-disabled;
      cursor: default;
    }
  }
}

.maxtable.cell-vertical-align-top td {
  vertical-align: top;
  padding: 20px 10px;

  a,
  span,
  img,
  div {
    vertical-align: top;
  }
}

.maxtable-dark tbody td {
  border-bottom: solid 1px #e5e5e5;
}

.maxtable-tableheader .customButtons .tableheader-button * {
  padding: 2px 10px;
}

.maxtable-tableheader .customButtons {
  float: right;
  margin-top: 5px;
}

.maxtable-tableheader {
  background: whitesmoke;
  float: left;
  width: 100%;
}

.maxtable-search {
  padding-left: 16px;
  padding-top: 5px;
  padding-bottom: 2px;
  width: 300px;
  float: left;
}

.maxtable-search > .mui-textfield > input:focus {
  height: 32px !important;
  margin-bottom: 10px !important;
}

.maxtable-search > .mui-textfield {
  margin-bottom: 0 !important;
  padding-top: 0 !important;
}

.maxtable-export {
  padding-right: 16px;
  padding-top: 5px;
  padding-bottom: 2px;
  float: right;
}

.tableheader-button span {
  margin-left: 10px;
  font-size: 12px !important;
  color: #555 !important;
  display: inline-table;
  text-transform: uppercase;
}

.tableheader-button a {
  color: black;
}

.fz-table-header-button {
  letter-spacing: 0.3px;
  font-weight: bold;
  color: white;
  width: 100px;
  padding: 8px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.table-search-box {
  display: inline-block;
  border-radius: 12px;
  border: 1px solid #dbdbdb;
  padding: 6px;
  padding-left: 8px;
  background-color: white;
  order: 2;
  margin-left: 15px;

  span {
    margin-right: 6px;
  }

  input {
    font-size: $default-font-size;
    width: 150px;
    background-color: unset;
    line-height: 1;
    padding: 0;
    border: none;
    outline: none;
  }
}

.table-backdrop {
  padding: 20px 40px;
  height: 100%;
  background-color: #f0f0f0;
}

.tableheader {
  display: flex;
  align-items: center;
  margin: 10px 0 30px 0;
  background-color: unset;
}

.tableheader-title {
  font-size: $page-title-font-size;
  line-height: 1;
  order: 1;
  text-rendering: optimizelegibility;
  color: #333;
}

.titlelink {
  display: inline-flex;
  align-items: baseline;
  margin-left: 12px;
  a {
    color: #1987d6;
    &:hover {
      text-decoration: underline;
      color: #1987d6;
    }
  }
}
.titlelink__icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.table-header-buttons {
  order: 4;
  margin-left: auto;
  display: flex;
  align-items: center;

  *:not(:last-child) {
    margin: 0;
    margin-right: 16px;
  }
}

.tableheader-pdf-report {
  padding-left: 10px;
  padding-right: 10px;
  background: white;
}

.tableheader-pdf-report-h4 {
  font-size: 22px;
  padding-left: 10px;
  color: gray;
  display: inline-table;
  font-weight: 100;
  margin-top: 4px;
}

.maxtable--inline {
  padding: 0;

  th {
    font-size: $large-font-size;
  }
}

.activity-table {
  td {
    font-size: 14px;
    width: 33.3%;
  }
}

.reporting-table {
  table {
    font-size: 14px;
    font-family: "Roboto";
  }
}

.table-search-box-with-button {
  display: flex;
  width: 40%;
  border-radius: 12px;
  border: 1px solid #dbdbdb;
  padding-left: 8px;
  background-color: white;
  order: 2;
  margin-left: 15px;

  span {
    margin-right: 6px;
  }

  input {
    margin: 6px;
    margin-left: 0px;
    font-size: $default-font-size;
    width: 90%;
    background-color: unset;
    line-height: 1;
    padding: 0;
    border: none;
    outline: none;
  }
}

.search-button {
  margin-left: auto;
  background-color: #cdcdcd;
  border-radius: 8px;
  border: none;
  color: $white;
  cursor: pointer;
  font-size: $small-font-size;
  font-weight: 400;
  padding: 8px 12px;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;

  &.active {
    background-color: #1987d6;
    i {
      color: $white;
    }
  }

  i {
    color: #7a869a;
  }
}

.table-search-wrapper {
  margin-left: 15px;
  display: flex;
  order: 3;
}

.table-search-reset {
  padding: 6px 12px;
  background-color: #cdcdcd;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  width: fit-content;
  margin-right: 7px;
}

.table-search-reset .label {
  float: left;
  margin-right: 5px;
}

.table-search-reset:hover {
  background-color: gray;
}
