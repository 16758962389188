$border-color: #e7e7e7;
$chat-blue: #0075DB;

.feedback-modal {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    width: 450px;
    padding: 32px;
}

.feedback-modal_title {
    font-size: 18px;
    font-weight: 500;
}

.feedback-modal_text {
    padding-top: 8px;
    color: #42526E;
    text-align: center;
}

.feedback-modal_icon {
    margin-bottom: 16px;
}

.feedback-modal_textArea {
    margin-top: 24px;
    width: 386px;
    height: 140px;
    border-color: $border-color;

    &:focus {
        outline: none;
    }
}

.feedback-modal_button {
    border: none;
    border-radius: 5px;
    padding: 7.5px 16px;
    margin-top: 16px;
    cursor: pointer;
    font-weight: 500;
}

.feedback-modal_submitButton {
    width: 128px;
    height: 40px;
    background: $chat-blue;
    color: $white;
}

.feedback-modal_editButton {
    background: $white;
    color: #094C99;
}

.feedback-modal_submitButtonDisabled {
    background: #DFE1E6;
    color: #7A869A;
}

.feedback-modal_editButtonDisabled {
    background: $white;
    color: #7A869A
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}

.spinner {
    display: inline-block;
    border: 2px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    height: 16px;
    width: 16px;
    margin-top: 5px;
}
