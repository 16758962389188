.password-reset-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  max-width: 400px;
}

.password-reset-message-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.password-item {
  display: inline;
  background-color: white;
  flex-flow: nowrap;
}

.password-item.show-hide-widget {
  border-style: none;
}

.show-hide-widget {
  display: inline;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: $universal-brand-blue;
}

.show-hide-widget:focus {
  outline: 0px;
}

.password-item-inputbox {
  background-color: #F5F5F5;
  padding: 5px;
  border: 1px solid #CFCFCF;
  width: 100%;
  max-width: 400px;
}
