.listButton {
    float: left;
}

.editModal .modal-body {
    overflow-y: unset;
}

.letter-panel-header .formgroup_title_rightlink_button {
    margin: 0;
}

.criteria-remove i {
    cursor: pointer;
}

div.selected-options {
    max-height: 245px;
    overflow-y: auto;
}

.criteria-remove {
    float: left;
    height: 20px;
}

div.criteria-label {
    font-size: inherit;
    border-left: none;
    padding-left: 0;
}

#startIpInput {
    width: 219px !important;
}

#endIpInput {
    width: 220px !important;
    margin-left: 10px;
    padding-right: 0;
}

.filtering-pill {
    display: inline-block;
    margin-right: 10px;
    color: white;
    border-radius: 3px;
    font-size: 12px;
    padding: 2px 10px;
}

.block-pill {
    background-color: orange;
}

.allow-pill {
    background-color: #90E070;
}

.sticky-pill {
    background-color: gray;
}

#transferSize {
    width: 70%;
    float: left;
}

#transferSize input {
    padding: 10px;
}

.transferSizeType {
    width: 20%;
    float: right;
    margin-top: -4px;
}

#criteriaSelector {
    width: 100%;
    margin-top: -30px;
}